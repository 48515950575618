exports.components = {
  "component---src-layouts-texty-page-layout-tsx-content-file-path-content-cookie-policy-mdx": () => import("./../../../src/layouts/TextyPageLayout.tsx?__contentFilePath=/opt/build/repo/content/cookie-policy.mdx" /* webpackChunkName: "component---src-layouts-texty-page-layout-tsx-content-file-path-content-cookie-policy-mdx" */),
  "component---src-layouts-texty-page-layout-tsx-content-file-path-content-disclaimer-mdx": () => import("./../../../src/layouts/TextyPageLayout.tsx?__contentFilePath=/opt/build/repo/content/disclaimer.mdx" /* webpackChunkName: "component---src-layouts-texty-page-layout-tsx-content-file-path-content-disclaimer-mdx" */),
  "component---src-layouts-texty-page-layout-tsx-content-file-path-content-privacy-policy-mdx": () => import("./../../../src/layouts/TextyPageLayout.tsx?__contentFilePath=/opt/build/repo/content/privacy-policy.mdx" /* webpackChunkName: "component---src-layouts-texty-page-layout-tsx-content-file-path-content-privacy-policy-mdx" */),
  "component---src-layouts-texty-page-layout-tsx-content-file-path-content-terms-of-service-mdx": () => import("./../../../src/layouts/TextyPageLayout.tsx?__contentFilePath=/opt/build/repo/content/terms-of-service.mdx" /* webpackChunkName: "component---src-layouts-texty-page-layout-tsx-content-file-path-content-terms-of-service-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-tsx": () => import("./../../../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */)
}

